// 引入axios
import axios from 'axios'
import { Promise } from 'core-js'
import { Message } from 'element-ui'
import store from '@/store'
import { merge } from "lodash";
import qs from "qs"



const ContentType = {
  1: "application/json",
  2: "application/x-www-form-urlencoded",
  3: "multipart/form-data",
};
// 创建axios 实例
const service = axios.create({
  baseURL:' http://api.yjvr.top/panorama/', // 基准地址
})
// 给实例添加请求拦截器
service.interceptors.request.use(
  // 成功的回调 config 是请求的配置信息
  config => {
    // 判断是否有token
    if (store.getters.token) {
      // 有就注入token
      config.headers['Authorization'] = `Bearer ${store.getters.token}`
    }
    return config // 必须要返回
  },
  // 失败的回调
  error => {
    return Promise.reject(error)
  }
)
// 给实例添加 响应的拦截器
service.interceptors.response.use(
  // 成功的回调（接口有没有调用成功）
  (response) => {
    // data 其实就是后台返回的真实数据，也是页面需要的数据
    const { success, message, data } = response.data
    // 根据success的成功与否（true返回拿到data，失败就直接报错）
    if (success) { // 业务有没有成功
      return data
    } else {
      // 提示错误信息，返回失败的状态
      Message.error(message)
      // return Promise.reject(new Error(message))
    }
  },
  // 失败的回调
  error => { // 统一提示错误：
    return Promise.reject(error)
  }
)


// const request = (url, data = {}, type = 'get', config = {}) => {
//   const defaultConfig = {
//     headers: {
//       "Content-Type": ContentType[1],
//       isLoading: true,
//     },
//   };
//   config = merge(defaultConfig, config);
//   return new Promise((resolve, reject) => {
//     let promise;
//     switch (type) {
//       case 'get':
//         url += "?" + qs.stringify(data);
//         promise = service.get(url);
//         break;
//       case 'post':
//         if (config?.headers["Content-Type"] === ContentType[2]) {
//           data = qs.stringify(data);
//         }
//         promise = service.post(url, data, config);
//         break;
//     }
//     promise.then((res) => resolve(res?.data)).catch((error) => reject(error));
//   });
// };

// export default request;


export default service
