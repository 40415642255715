<template>
  <div>
    <!-- 导航栏 -->
    <navbar></navbar>
    <!-- banner图 -->
    <div class="banner">
      <iframe src="https://720yun.com/t/79vkbeiq0rq?scene_id=88596262" frameborder="0"
        style="position: none !important;"></iframe>
      <div class="banner-title">
        <span class="index-en-title">YujianVR -Meet the future in the present</span>
        <span class="cn-title">宇见VR -在现在遇见未来</span>
      </div>
    </div>

    <!-- 作品展示 -->
    <div class="recommend">
      <div class="container">
        <div class="title-nav">
          <div class="title">官方案例</div>
          <p style="color:#000000;">
            <router-link to="/product">更多</router-link>
          </p>
        </div>
        <div class="product">
          <!-- 一个块 -->
          <div class="product-content">
            <a href="./pro-detail.html">
              <div class="product-img">
                <img src="../assets/images/dtqyj.png" alt="" />
              </div>
              <span class="product-title"> 大唐奇遇记 </span>
              <div class="product-nr">
                <span style="margin: 0">官方</span>
                <span style="margin: 0">游戏</span>
              </div>
            </a>
          </div>
          <!-- /一个块 -->
          <div class="product-content">
            <a href="./pro-detail1.html">
              <div class="product-img">
                <img src="../assets/images/wzdyt.png" alt="" />
              </div>
              <span class="product-title"> 玩转大雁塔 </span>
              <div class="product-nr">
                <span style="margin: 0">官方</span>
                <span style="margin: 0">游戏</span>
              </div>
            </a>
          </div>
          <div class="product-content">
            <a href="./pro-detail2.html">
              <div class="product-img">
                <img src="../assets/images/gxq.png" alt="" />
              </div>
              <span class="product-title"> 高新国际会议 </span>
              <div class="product-nr">
                <span style="margin: 0">官方</span>
                <span style="margin: 0">展会</span>
              </div>
            </a>
          </div>
          <div class="product-content">
            <a href="./pro-detail3.html">
              <div class="product-img">
                <img src="../assets/images/ylmls.png" alt="" />
              </div>
              <span class="product-title"> 杨凌马拉松 </span>
              <div class="product-nr">
                <span style="margin: 0">官方</span>
                <span style="margin: 0">赛事</span>
              </div>
            </a>
          </div>
        </div>
        <!-- 补充块元素，flex布局对齐方式，少一个也会两端对齐，放3个高度为0的div来占位，用来适应最后一行不足四个的情况 -->
        <div class="product-content" style="height: 0; margin: 0"></div>
        <div class="product-content" style="height: 0; margin: 0"></div>
        <div class="product-content" style="height: 0; margin: 0"></div>
      </div>
    </div>


    
    <!-- 关于我们 -->
    <div class="about-us">
      <div class="container">
        <div class="title-nav about-title">
          <div class="title">我们的优势</div>
        </div>
      </div>
      <!-- 左文右图 -->
      <div class="about-cont">
        <div class="container">
          <div class="btn-titles">实体馆搬到线上</div>
          <div class="about-flex">
            <div class="ab-left">
              <p>
                真实存在的线下展示场所 <br />
                通过全景拍摄、后期特效制作 <br />
                1 : 1 完美还原上云，更多交互功能实现 <br />
                成为口袋里的云展馆，走到哪里带到哪里
              </p>
            </div>
            <div class="ab-right">
              <img src="../assets/images/advantage1.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <!-- 左图右文 -->
      <div class="about-cont">
        <div class="container">
          <div class="btn-titles">线上形象落地展示</div>
          <div class="about-flex">
            <div class="ab-left">
              <img src="../assets/images/advantage1.png" alt="" />
            </div>
            <div class="ab-right">
              <p>
                为工会定制的线下形象展示体验<br />
                通过触摸大屏、VR眼镜等设备 <br />
                实现沉浸式体验 <br />
                进一步展现工会形象
              </p>
            </div>
          </div>
          <div class="btndiv">
            <button>更&nbsp;&nbsp;&nbsp;多&nbsp;+</button>
          </div>
        </div>
      </div>
    </div>

    <!-- 制作工具 -->
    <div class="tool">
      <div class="container">
        <div class="title-nav about-title">
          <div class="title">制作工具</div>
        </div>
        <div class="tool-container">
          <div class="tool-left">
            <img src="../assets/images/tool.png" alt="" />
            <div class="blue"></div>
          </div>
          <div class="tool-right">
            <h2>简单高效易上手的VR<br />全景制作工具</h2>
            <p>快速制作切换场景，文字转语音，添加互动效果，测距等</p>
            <a href="https://test.yjvr.top/#/WM010S01"><button>立即使用</button></a>
          </div>
        </div>
      </div>
    </div>

    <!-- 加入我们 -->
    <div class="us">
      <div class="container us-title">
        <p>宇见VR-在现在遇见未来</p>
        <a href="https://test.yjvr.top/#/WM010S01"><button>加入我们</button></a>
      </div>
    </div>

    <!-- 底部 -->
    <basee></basee>

    <!-- 侧边固定 -->
    <div>
      <div class="broadside-box">
        <div class="br-box-one" @mouseenter="onMouseOver" @mouseleave="onMouseOut">
          <img src="../assets/images/consult1.png" alt="" v-if="showweixin" />
          <img src="../assets/images/consult2.png" alt="" v-if="show" />
        </div>
        <div class="br-title">微信咨询</div>
        <div class="br-img" v-if="showpicture">
          <img src="../assets/images/showqrcode.jpg" alt="" style="
              width: 150px;
              height: 150px;
              box-shadow: 0px 4px 10px 0px rgb(0 0 0 / 20%);
            " />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navbar from '@/components/nav.vue'
import basee from '@/components/button.vue'
export default {
  data() {
    return {
      showpicture: false,
      showweixin: true,
      show: false,
      status: localStorage.getItem("uuid")
    }

  },
  components: {
    navbar,
    basee
  },
  created() {

  },
  methods: {
    onMouseOver() {
      this.showpicture = true,
        this.showweixin = false
      this.show = true
    },
    onMouseOut() {
      this.showpicture = false,
        this.showweixin = true
      this.show = false
    }
  }
}
</script>

<style lang="less" scoped>
@import '../css/common.css';
@import '../css/index.css';
@import '../css/font-awesome/css/font-awesome.min.css';

nav {
  padding: 0;
}

/deep/iframe {
  position: unset !important;
}

.broadside-box {
  position: fixed;
  right: 20px;
  bottom: 12vw;
  width: 68px;
  background: #FFFFFF;
  box-shadow: 0px 4px 10px 0px rgb(0 0 0 / 10%);
  border-radius: 40px;
  z-index: 10;
  padding-top: 24px;
  padding-bottom: 24px;

}

.br-box-one {
  display: inline-block;
  font-size: 0;
  align-items: center;
  display: flex;
}

.br-title {
  height: 16px;
  font-size: 12px;
  font-weight: 400;
  color: #1A1A1A;
  line-height: 16px;
  text-align: center;
  margin-top: 5px;
}

.br-img {
  position: absolute;
  left: -174px;
  top: -15%;
}

/deep/.blue {
  position: absolute;
  width: 85%;
  height: 18.5vw;
  background-color: rgb(38, 112, 240);
  top: 1vw;
  left: 1vw;
  z-index: -1;
  right: 0vw !important;
}
</style>