import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    // component: Home
    component: () => import(/* webpackChunkName: "about" */ '../views/home.vue')
    // children: [
    //   { path: '/login', component: Login }
    // ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login.vue')
  },
  {
    path: '/guanyu',
    name: 'guanyu',
    component: () => import( '../views/guanyu.vue')
  },
  {
    path: '/product',
    name: 'product',
    component: () => import('../views/product.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import( '../views/about.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import( '../views/contact.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router