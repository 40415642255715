import request from '@/utils/request'

// 登录接口封装
export function login(data) {
  return request({
    url: 'wechat/ticket',
    method: 'post',
    data
  })
}


