<template>
  <div>
    <nav class="nav">
      <nav class="navbar">
        <div class="logo">
          <router-link to="/home"><img src="../assets/images/logo.png" alt="" /></router-link>

        </div>
        <ul class="nav-nav">
          <li>
            <router-link to="/home">首页</router-link>
          </li>
          <li>
            <router-link to="/product">官方案例</router-link>
          </li>
          <li>
            <router-link to="/contact">联系我们</router-link>
          </li>
          <li>
            <router-link to="/about">关于我们</router-link>
          </li>
          <!-- <a href="https://test.yjvr.top/#/WM010S01"> -->
          <li @click="makebtn">
            <el-button type="primary" size="small">制作工具</el-button>

            <router-link to="/login">
              <el-button type="primary" size="small" v-if="uuid == null">登录注册</el-button>
            </router-link>
          </li>
          <!-- </a> -->
          <!-- <li>
           
          </li> -->

          <li v-if="uuid" style="margin-left: -30px;" class="account">

            <el-dropdown v-if="token" @command="cearl" >
              <span class="el-dropdown-link">
                <el-avatar :size="25" :src="userInfo.avatarUrl" :alt="userInfo.userNickname"> </el-avatar>
                <span class="text-base color-white ml-1 text-bold" style=" margin-left: 5px;color:#fff;">{{
                    userInfo.userNickname
                }}</span>
              </span>
              <!-- {{userInfo}} -->

              <template #dropdown trigger>
                <el-dropdown-menu >
                  <el-dropdown-item  @click="cearl">退出登录</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <!-- <el-button type="primary" size="small">退出登录</el-button> -->
          </li>
        </ul>
      </nav>
    </nav>
    <!-- 二级路由
    <router-view></router-view> -->
  </div>
</template>

<script>
import Cookies from 'js-cookie'
export default {
  data() {
    return {
      uuid: localStorage.getItem("uuid"),
      token: localStorage.getItem("token"),
      userInfo: JSON.parse(localStorage.getItem("userInfo")) 

    }
  },
  methods: {
    cearl() {
      localStorage.clear();
      Cookies.remove('uuid')
      Cookies.remove('token')
      Cookies.remove('userInfo')
      window.location.href = "./index.html"


    },
    makebtn() {
      if (this.uuid == null) {
        this.$router.push('/login')
      }
      else {
        // window.location.href = `https://test.yjvr.top/#/WM010S01`

        window.location.href = `https://test.yjvr.top/client/index.html#/`


        // ?token=${this.token}
        // window.open("http://localhost:8081/#/WM010S01?token=" +
        //           `${this.token}` ) 
      }

    }
  }
}
</script>

<style scoped lang="less">
@import '../css/common.css';
@import '../css/index.css';

.el-button--small {
  padding: 9px 15px;
  font-size: 12px;
  border-radius: 3px;
  margin-left: 15px;
}

.account {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .el-dropdown-link {
    display: flex;
    align-items: center;
  }
}
.el-dropdown-menu{
  background-color: rgb(0, 0, 0);
  border: 1px solid #000000;
}
.el-dropdown-menu__item  {
  color: #fff;
}
.el-dropdown-menu__item:focus, .el-dropdown-menu__item:not(.is-disabled):hover {
    background-color: #0a0a0a; 
    color: #d3d3d3; 
}
</style>