
import { getToken, setToken, removeToken } from '@/utils/auth'
import { login } from '@/api/user' // 引入接口
// 数据持久化
// 状态
const state = {
  token: getToken(), // 从缓存里读取token，将数据持久化
  userInfo: {} // 这里设置成一个空对像，保持数据的一致性

}
// 修改数据
const mutations = {
  setToken(state, token) {
    state.token = token // 将vuex的token进行修改
    // 同步缓存
    setToken(token)
  },
  // 删除token
  removeToken(state) {
    state.token = null // 将数据置空，删除vuex里的token
    removeToken() // 同步到缓存
  },
  setUserInfo(state, result) {
    // 更新一个对象
    state.userInfo = result // 这是一个响应式的
  },
  // 删除用户信息
  removeUserInfo(state) {
    state.userInfo = {}
  }
}
// 异步获取
// 获取到的数据要存，就放在vuex里，那么优先在actions中发请求
// 为什么要往vuex中存的数据要放到 actions
// 因为vuex 中的数据大多数都是页面共享的， 那么肯定会出现很多页面要修改，放到actions 中，每个页面都直接调用
const actions = {
  async login(context, data) {
    // 调用API的接口
    const result = await login(data)
    // 判断是否获取成功
    // 做持久化，调用mutations
    context.commit('setToken', result)
   
  },
  logout(context) {
    // 删除token
    context.commit('removeToken')
    // 删除用户信息
    context.commit('removeUserInfo')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
